import axios from 'axios'

type HealthResponse = {
  message: string
}

const baseUrl = 'https://api.sheek.bio'

export async function checkHealth(): Promise<HealthResponse> {
  return axios.get(baseUrl).then((res) => res.data)
}
