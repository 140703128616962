import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import { checkHealth } from './services/health'

function App() {
  const [message, setMessage] = useState('Loading Check')

  //  Check health state on first render
  useEffect(() => {
    ;(async () => {
      const response = await checkHealth()

      setMessage(response.message)
    })()
  })

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>{message}</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        ></a>
      </header>
    </div>
  )
}

export default App
